import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static values = { loadUrl: String }
  static targets = ['loadable']

  async loadHtml(event) {
    event.preventDefault();

    const url = this.hasFetchUrlValue ? this.loadUrlValue : event.currentTarget.href;
    const response = await axios.get(url, { headers: { 'Accept': 'text/html' } })

    this.loadableTarget.outerHTML = response.data;
  }
}